<template>
  <page>
    <module enable-flex>
      <m-search ref="search" @search="searchHandle">
        <m-search-item label="机台名称">
          <c-input name="machine_name"></c-input>
        </m-search-item>
      </m-search>

      <m-operate>
        <c-button v-if="auth('b_machine_edit')" color="sys" @click="createMachine">新建机台</c-button>
      </m-operate>

      <c-table ref="table" height="grow">

        <c-table-column
            label="机台名称"
            width="200"
            name="machine_name"
        ></c-table-column>


        <c-table-column
            label="备注"
            width="300"
            name="remark"
        >
          <template #default="{data}">
            <span v-if="data.remark">{{data.remark}}</span>
            <span v-else>--</span>
          </template>
        </c-table-column>

		<c-table-column
		    label="创建时间"
		    width="140"
		    name="create_date"
		></c-table-column>

        <c-table-column
            type="button"
            label="操作"
            width="120"
        >
          <template v-slot="{data}">
            <c-table-button v-if="auth('b_machine_edit')" @click="editMachine(data)">编辑</c-table-button>
			<c-table-button v-if="auth('b_machine_edit')" @click="deleteMachine(data)">删除</c-table-button>
          </template>
        </c-table-column>
      </c-table>
    </module>

    <c-dialog ref="machineEdit" :title="editState ? '编辑机台' : '新建机台'" width="600" @resolve="submit">
      <c-form ref="machineForm" unit-width="100%">
        <c-form-item label="机台名称" required>
          <c-input name="machine_name"></c-input>
        </c-form-item>
        <c-form-item label="备注">
	        <c-input type="textarea" name="remark"></c-input>
        </c-form-item>
        <c-input type="hidden" name="id"></c-input>
      </c-form>
    </c-dialog>

  </page>
</template>

<script>
export default {
  name: 'm_machine',
  components: {
  },
  data() {
    return {
      editState: false
    }
  },
  computed: {
  },
  mounted() {
    this.$refs.search.search();
  },
  methods: {
    tabHandle(item) {
      this.$refs.search.search();
    },
    searchHandle(data) {
      this.$refs.table.load({
        url: '/dic/machine/list',
        data
      });
    },
    //创建机台
    createMachine() {
      this.editState = false;
      this.$refs.machineForm.clear();
      this.$refs.machineForm.set({});
      this.$refs.machineEdit.open();
    },
    //编辑机台
    editMachine(val) {
      this.editState = true;
      this.$refs.machineForm.set(val);
      this.$refs.machineEdit.open();
    },
	//删除机台
	deleteMachine(val){
		this.$confirm({
		  title: '确认',
		  message: '确定要删除机台【'+val.machine_name+'】吗？',  // 提示内容,
		  buttonName: '确定',  // 确定按钮文字
		  cancelButton: true,  // 是否显示取消按钮
		  resolve: () => {
		    this.request({
		      url: '/dic/machine/delete',
		      data: {id:val.id},
		      loading: true,
		      success: data => {
		        this.$message({
		          message: '删除成功',
		          type: 'success'
		        });
				this.$refs.table.update();
		      }
		    });
		  },  // 确定按钮回调
		  cancel: () => {},  // 取消按钮回调
		});
	},
    //提交保存
    submit(stop) {
      stop();
      this.$refs.machineForm.submit({
        url: this.editState ? '/dic/machine/update' : '/dic/machine/add',
        rule: {
          machine_name: '请填写机台名称'
        },
        dataFormatter: data => {
          return data;
        },
        success: (data, res) => {
          this.$message({
            message: '保存成功',
            type: 'success'
          });
          this.$refs.table.update();
          this.$refs.machineEdit.close();
        }
      });
    }
  }
}
</script>